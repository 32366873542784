import { Route, Switch } from "react-router-dom";
import React from 'react';

import ViewHome from './Home';
import TeamOrder from "./TeamOrder";
import Error from "./Error";
import Help from "./Help";

const View = () => {
    return (
        <Switch>
            <Route exact path="/error" component={Error} />
            <Route exact path="/" component={Help} />
            <Route exact path="/:uuid" component={TeamOrder} />
            <Route exact path="/group_order/:slug_uuid" component={ViewHome} />
        </Switch>
    )
}
export default View;