import HttpService from "../HttpService";

export const makeOrder = async (data) => {
    const {id, group_order_items} = data

    const res = await HttpService({
        method: 'POST',
        url: '/api/v2/group_orders/' + id + '/group_order_items',
        data: {
            group_order_items
        }
    });

    return res.data;
};

export const deleteMyOrder = async (data) => {
    const {id, group_order_items} = data

    const res = await HttpService({
        method: 'DELETE',
        url: '/api/v2/group_orders/' + id + '/group_order_items/cancel_my_order',
        data: {
            group_order_items
        }
    });

    return res.data;
};

export const fetchMyProducts = async (id) => {

    const res = await HttpService({
        method: 'GET',
        url: '/api/v2/group_orders/' + id + '/my_order'
    });

    return res.data;
};