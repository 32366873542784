import './style.scss';
import React, {useEffect, useState} from 'react';
import Invitation from "./Invitation";
import MyOrder from "./MyOrder";
import TopTabs from "./TopTabs";
import {useDispatch, useSelector} from "react-redux";
import {
    COMMON_CHANGE_BUDGET, COMMON_CLEAR_OLD_GO, COMMON_GET_MY_MEMBER,
    FETCH_GROUP_ORDER, FETCH_GROUP_ORDER_ITEMS,
} from "../../store/Common/Common.constant";
import {withRouter} from "react-router-dom";
import Modals from "../../modules/ui/Modals";
import {FETCH_OLD_MY_PRODUCTS, NEW_USER_PRODUCTS, PRODUCTS_CLEAR_OLD_GO} from "../../store/Products/Products.constant";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CLOSE_MODAL_AUTH, MODAL_CLEAR_OLD_GO, MODAL_TOGGLE, NEW_USER_MODAL} from "../../store/Modal/Modal.constant";
import {format} from "date-fns";
import {Helmet} from "react-helmet";
import {WidgetFooter, WidgetHeader} from "../../widgets";
import {FORM_CLEAR_OLD_GO} from "../../store/Form/Form.constant";

const ViewHome = ({match}) => {
    const dispatch = useDispatch();
    const common = useSelector(state => state.common);
    const products = useSelector(state => state.products[match.params.slug_uuid]);
    const form = useSelector(state => state.form);
    const modals = useSelector(store => store.modal[match.params.slug_uuid]);
    const [title, setTitle] = useState('CateredClub');

    const onFocusPage = () => {
        if (common.settings?.[match.params?.slug_uuid]?.isJoin && common.settings?.[match.params.slug_uuid]?.auth?.group_order_token !== localStorage.getItem('token')) {
            localStorage.setItem('token', common.settings?.[match.params.slug_uuid]?.auth?.group_order_token);
            window.location.reload();
        }
    }
    window.addEventListener('focus', onFocusPage)

    let fetchPresets = () => {
        !modals && dispatch({type: NEW_USER_MODAL, payload: match.params.slug_uuid});
        !products && dispatch({type: NEW_USER_PRODUCTS, payload: match.params.slug_uuid});
        dispatch({type: FETCH_GROUP_ORDER, payload: match.params});
        onFocusPage();
        const clearDate = localStorage.getItem('clearDate');
        if (common?.groupOrders && (!clearDate || +clearDate < (new Date().valueOf() - 604800000))) {
            let arrOldUuid = Object.keys(common?.groupOrders).map(item => (
                common?.groupOrders[item]?.delivery_date_time > 0 && new Date(common?.groupOrders[item]?.delivery_date_time * 1000) < new Date() && match.params?.slug_uuid !== item && item
            )).filter(i => i);
            dispatch({type: COMMON_CLEAR_OLD_GO, payload: arrOldUuid});
            dispatch({type: MODAL_CLEAR_OLD_GO, payload: arrOldUuid});
            dispatch({type: PRODUCTS_CLEAR_OLD_GO, payload: arrOldUuid});
            dispatch({type: FORM_CLEAR_OLD_GO, payload: arrOldUuid});
            localStorage.setItem('clearDate', new Date().valueOf());
        }
    }
    useEffect(fetchPresets, [])

    const forceOpenModal = (name) => {
        dispatch({type: NEW_USER_MODAL, payload: match.params.slug_uuid});
        dispatch({type: CLOSE_MODAL_AUTH, payload: match.params.slug_uuid});
        dispatch({type: MODAL_TOGGLE, payload: {data: name, user_id: match.params.slug_uuid, boolean: true}});
    }

    const LoadGroupOrder = () => {
        const status = common.groupOrders?.[match.params.slug_uuid]?.status;
        modals?.isModalOrderEndCompleted && dispatch({type: MODAL_TOGGLE, payload: {data: 'isModalOrderEndCompleted', user_id: match.params.slug_uuid, boolean: false}});
        modals?.isModalOrderEndCancelled && dispatch({type: MODAL_TOGGLE, payload: {data: 'isModalOrderEndCancelled', user_id: match.params.slug_uuid, boolean: false}});
        (status === 'wait_for_delivery' || status === 'waiting_for_delivery' || status === 'delivered' || status === 'completed') && forceOpenModal('isModalOrderEndCompleted');
        (status === 'cancelled' || status === 'declined') && forceOpenModal('isModalOrderEndCancelled');
    }
    useEffect(LoadGroupOrder, [common.groupOrders?.[match.params.slug_uuid]])

    if (common.groupOrders?.[match.params.slug_uuid] && 'CateredClub' === title) {
        setTitle(`${common.groupOrders?.[match.params.slug_uuid]?.name} - ${common.groupOrders?.[match.params.slug_uuid]?.delivery_date_time ? format(new Date(common.groupOrders?.[match.params.slug_uuid].delivery_date_time * 1000), 'EEEE, MMMM do, h:mm aaa') : null}`)
    }

    let groupOrdersMembers = () => {
        if (common.settings?.[match.params?.slug_uuid]?.isJoin){
            dispatch({type: COMMON_GET_MY_MEMBER, payload: match.params.slug_uuid});
            dispatch({type: FETCH_OLD_MY_PRODUCTS, payload: {
                    id: common.settings?.[match.params.slug_uuid]?.auth.id,
                    menu: common?.groupOrders?.[match.params.slug_uuid]?.preset?.chefs?.[0].menus,
                    user_id: match.params?.slug_uuid}
            });
            dispatch({type: FETCH_GROUP_ORDER_ITEMS, payload: {data: common.groupOrders?.[match.params.slug_uuid]?.id, user_id: match.params.slug_uuid}});
        }
    }
    useEffect(groupOrdersMembers, [common.settings?.[match.params?.slug_uuid]?.isJoin])

    let changeProductsOrder = () => {
        let budget = common.groupOrders?.[match.params.slug_uuid] ? +common.groupOrders?.[match.params.slug_uuid].per_person_budget?.cents : 0;
        products?.myProducts && products.myProducts.map(item => (budget -= +item.total_price_cents));
        common.settings?.[match.params.slug_uuid]?.budget !== budget && dispatch({type: COMMON_CHANGE_BUDGET, payload: {data: budget, user_id: match.params.slug_uuid}})
    }
    useEffect(changeProductsOrder, [products?.myProducts, common.settings?.[match.params.slug_uuid]]);

    // console.log(common, products, form)

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta id="meta-description" name="description" content={title} />
            </Helmet>

            <WidgetHeader user_id={match.params?.slug_uuid} isJoin={common.settings?.[match.params?.slug_uuid]?.isJoin}
                          auth={common.settings?.[match.params.slug_uuid]?.auth} />
            <ToastContainer progressClassName='fancy-progress-bar'/>
            <Modals modals={modals} form={form} products={products} slug_uuid={match.params.slug_uuid} common={common} />
            <Invitation user_id={match.params.slug_uuid} isJoin={common.settings?.[match.params?.slug_uuid]?.isJoin}
                        groupOrders={common.groupOrders?.[match.params.slug_uuid]}
                        settings={common.settings?.[match.params.slug_uuid]} />
            <MyOrder slug_uuid={match.params.slug_uuid} settings={common.settings?.[match.params.slug_uuid]}
                     currency={common.groupOrders?.[match.params.slug_uuid]?.tax_info?.currency}
                     joined_member_count={common.groupOrders?.[match.params.slug_uuid]?.joined_member_count}
                     head_count={common.groupOrders?.[match.params.slug_uuid]?.head_count}
                     isJoin={common.settings?.[match.params?.slug_uuid]?.isJoin} products={products} />
            <TopTabs auth_id={common.settings?.[match.params.slug_uuid]?.auth?.id} user_id={match.params.slug_uuid}
                     id={common.groupOrders?.[match.params.slug_uuid]?.id}
                     joined_member={common.groupOrders?.[match.params.slug_uuid]?.joined_member_count}
                     currency={common.groupOrders?.[match.params.slug_uuid]?.tax_info?.currency}
                     isJoin={common.settings?.[match.params?.slug_uuid]?.isJoin} products={products}
                     groupOrdersMembers={common.groupOrders?.[match.params.slug_uuid]?.group_order_members}
                     groupOrdersItems={common.groupOrders?.[match.params.slug_uuid]?.group_order_items}
                     presets={common.groupOrders?.[match.params.slug_uuid]?.preset}
                     settings={common.settings?.[match.params.slug_uuid]} />
            <WidgetFooter />
        </>
    )
}



export default withRouter(ViewHome)
