import React from 'react';
import ModalProduct from "./ModalProduct/index";
import {useDispatch} from "react-redux";
import {
    CLOSE_MODAL_AUTH,
    MODAL_TOGGLE_PRODUCT,
    MODAL_TOGGLE, MODAL_CHANGE_ORDER
} from "../../../store/Modal/Modal.constant";
import './style.scss'
import ModalAuth from "./ModalAuth";
import ModalReplaced from "./ModalReplaced";
import ModalOrderSuccess from "./ModalOrderSuccess";
import ModalConfirmation from "./ModalСonfirmation";
import ModalShareLink from "./ModalShareLink";
import ModalChangeOrder from "./ModalChangeOrder";
import ModalOrderEnd from "./ModalOrderEnd";
import ModalEditProfile from "./ModalEditProfile";

const Modals = ({common, slug_uuid, products, form, modals}) => {
    const dispatch = useDispatch();
    return (
        <>
            {modals?.isModalEditProfile ? <ModalEditProfile error={form.form?.error?.message} user_id={slug_uuid}
                groupOrders={common.groupOrders?.[slug_uuid]}
                show={modals.isModalEditProfile} auth={common.settings?.[slug_uuid]?.auth}
                onHide={() => dispatch({type: MODAL_TOGGLE, payload: {data: 'isModalEditProfile', user_id: slug_uuid}})}/> : null}
            {modals?.isModalOrderEndCompleted ? <ModalOrderEnd order_deadline={common.groupOrders?.[slug_uuid]?.order_deadline * 1000}
                groupOrders={common.groupOrders?.[slug_uuid]} show={modals.isModalOrderEndCompleted}
                date={common.groupOrders?.[slug_uuid]?.delivery_date_time * 1000} title="COMPLETED"
                onHide={() => dispatch({type: MODAL_CHANGE_ORDER, payload: {user_id: slug_uuid, slug: 'isModalOrderEndCompleted', info: null}})}/> : null}
            {modals?.isModalOrderEndCancelled ? <ModalOrderEnd order_deadline={common.groupOrders?.[slug_uuid]?.order_deadline * 1000}
                groupOrders={common.groupOrders?.[slug_uuid]} show={modals.isModalOrderEndCancelled}
                date={common.groupOrders?.[slug_uuid]?.delivery_date_time * 1000} title="CANCELLED"
                onHide={() => dispatch({type: MODAL_CHANGE_ORDER, payload: {user_id: slug_uuid, slug: 'isModalOrderEndCancelled', info: null}})}/> : null}
            {modals?.isModalChangeOrder ? <ModalChangeOrder changeOrderInfo={modals.changeOrderInfo} show={modals.isModalChangeOrder} user_id={slug_uuid}
                onHide={() => dispatch({type: MODAL_CHANGE_ORDER, payload: {user_id: slug_uuid, slug: 'isModalChangeOrder', info: null}})}/> : null}
            {modals?.isModalShareLink ? <ModalShareLink show={modals.isModalShareLink}
                onHide={() => dispatch({type: MODAL_TOGGLE, payload: {data: 'isModalShareLink', user_id: slug_uuid}})}/> : null}
            {modals?.isModalReplaced ? <ModalReplaced user_id={slug_uuid} show={modals.isModalReplaced}
                onHide={() => dispatch({type: MODAL_TOGGLE, payload: {data: 'isModalReplaced', user_id: slug_uuid}})}/> : null}
            {modals?.isModalOrderSuccess ? <ModalOrderSuccess groupOrders={common.groupOrders?.[slug_uuid]} show={modals.isModalOrderSuccess}
                onHide={() => dispatch({type: MODAL_TOGGLE, payload: {data: 'isModalOrderSuccess', user_id: slug_uuid}})}/> : null}
            {modals?.isModalAuth ? <ModalAuth date={common.groupOrders?.[slug_uuid]?.delivery_date_time * 1000} error={form.form?.error?.message}
                groupOrders={common.groupOrders?.[slug_uuid]} user_id={slug_uuid} show={modals.isModalAuth}
                onHide={() => dispatch({type: CLOSE_MODAL_AUTH, payload: slug_uuid})}/> : null}
            {modals?.modalProduct ? <ModalProduct user_id={slug_uuid} show={modals.isModalProduct} orderTeam={modals.orderTeam}
                modalProductFlag={modals.modalProductFlag} budget={common.settings?.[slug_uuid]?.budget} products={products}
                currency={common.groupOrders?.[slug_uuid]?.tax_info?.currency} groupOrdersId={common.groupOrders?.[slug_uuid].id}
                shared_url={common.groupOrders?.[slug_uuid]?.preset?.shared_url} myProductsId={products?.myProductsId}
                logo_image={common.groupOrders?.[slug_uuid]?.preset?.chefs[0]?.profile_image_url} product={modals.modalProduct}
                onHide={() => dispatch({type: MODAL_TOGGLE_PRODUCT, payload: slug_uuid})}/> : null}
            {modals?.isModalConfirmation ? <ModalConfirmation user_id={slug_uuid} isResendCode={common.settings?.[slug_uuid]?.isResendCode}
                error={form.form?.error?.message} show={modals.isModalConfirmation}
                order_deadline={common.groupOrders?.[slug_uuid]?.order_deadline * 1000}
                onHide={() => dispatch({type: MODAL_TOGGLE, payload: {data: 'isModalConfirmation', user_id: slug_uuid}})}/> : null}
        </>
    )
}
export default Modals