import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import Form from "../../Form";
import FormSubmit from "../../Form/FormSubmit";
import FormInputCustom from "../../Form/FormInput/FormInputCustom";
import {FORM_MODAL_AUTH} from "../../../../store/Form/Form.constant";
import {format} from "date-fns";
import FormImageUpload from "../../Form/FormInput/FormImageUpload";
import {useFormikContext} from "formik";
import * as Yup from 'yup';
import ImagePreview from "../../ImagePreview";
import FormSelect from "../../Form/FormSelect";
import FormSelectCheckbox from "../../Form/FormSelectCheckbox";
import dietaryOptions from "../../../../mock/dietaryOptions";

const ModalAuthWrap = ({user_id, additional_delivery_options, groupOrderId, auth, setAuth}) => {
    const {setFieldValue} = useFormikContext();
    const loadGroupOrder = () => {
        setFieldValue('id', groupOrderId);
        setFieldValue('user_id', user_id);
    };
    useEffect(loadGroupOrder, [groupOrderId, user_id]);

    const btnAuth = () => {
        setAuth(!auth)
        if (!auth) {
            setFieldValue('phone_logIn', '')
        }
    }

    return (
        <>
            <div className="already__have--acount">
                <p>
                    <span>
                        <img src="/img/haveaccount.svg" alt="haveaccount" />
                    </span> {auth ? 'Already have an account?' : 'Don’t have an account'}
                    <button onClick={btnAuth} type="button">{auth ? 'Sign In' : 'Create Account'}</button>
                </p>
            </div>
            {auth
                ? <>
                    <FormImageUpload name='profile_image' nameUrl='image_base64' uploadButton='Upload a profile picture' />
                    <div className='double__float'>
                        <FormInputCustom title='Your first name*' name='first_name' />
                        <FormInputCustom title='Your last name*' name='last_name' />
                    </div>
                    <div className="double__float">
                        <FormInputCustom title='Your email*' name='email' />
                        <FormInputCustom title='Your mobile phone number*' name='phone' />
                    </div>
                    {additional_delivery_options?.length
                        ? <FormSelect options={additional_delivery_options.map(item => ({value: item, label: item}))} name='selected_delivery_option' title='Select your floor #' />
                        : null
                    }
                    <FormSelectCheckbox name='dietary_types' options={dietaryOptions} title='Dietary Types (Choose or make your own)' />
                </>
                : <FormInputCustom title='Your mobile phone number*' name='phone_logIn' />
            }
            <div className="modal__submit">
                <FormSubmit disabled={!groupOrderId}>Start Ordering</FormSubmit>
            </div>
        </>
    )
}


const ModalAuth = ({onHide, user_id, show, groupOrders, error, date}) => {
    const [auth, setAuth] = useState(true);
    const [choiceAuth, setChoiceAuth] = useState(true);
    const [textError, setTextError] = useState('');
    const presets = groupOrders?.preset;
    const groupOrderId = groupOrders?.id;
    const getError = () => {
        if(error === 'Email has already been taken') {
            setAuth(false);
            setTextError('It looks like you already have signed in using this email in the past. You can sign in quickly by using your phone number ONLY, by clicking here.')
        }
    }
    useEffect(getError, [error]);

    const schema = {
        first_name: Yup.string().when("phone_logIn", (region, schema) => {
            return region?.length
                ? schema
                : schema.required('Required');
        }),
        last_name: Yup.string().when("phone_logIn", (region, schema) => {
            return region?.length
                ? schema
                : schema.required('Required');
        }),
        phone: Yup.string().when("phone_logIn", (region, schema) => {
            return region?.length
                ? schema
                : schema.required('Required');
        }),
        email: Yup.string().when("phone_logIn", (region, schema) => {
            return region?.length
                ? schema
                : schema.email('Invalid email').required('Required');
        })
    };

    const clickChoiceAuth = (method) => {
        setChoiceAuth(false);
        switch (method) {
            case 'SIGNIN': return setAuth(true)
            case 'SIGNUP': return setAuth(false)
            default: return
        }
    }

    return (
        <>
            <Modal show={show} size="lg" centered>
                <Modal.Body>
                    <div className="modal__wrapper">
                        <div className="inner__modal">
                            <div className="team__modal">
                                <div className="head__modal">
                                    <div className="restaurant__name">
                                        <h6>{presets?.name ? presets.name : 'loading...'}</h6>
                                        <p>{presets?.short_address ? presets.short_address : 'loading...'}</p>
                                    </div>
                                    {!groupOrders ? <div className='placeholder__image form__head' /> : <ImagePreview image={groupOrders?.preset?.banner_image_url} alt="modalimage" />}
                                    <div className="head__logo">
                                        <a className='logo__float' href={groupOrders?.preset?.shared_url ? groupOrders?.preset?.shared_url : '/'}>
                                            {!groupOrders
                                                ? <div className='placeholder__image form__head' />
                                                : <ImagePreview image={groupOrders?.preset?.chefs[0]?.profile_image_url} alt="headlogo" />
                                            }
                                        </a>
                                    </div>
                                </div>
                                <div className="modal__info">
                                    {choiceAuth
                                        ? <div className="modal__info--main">
                                            <h2>Join the team order</h2>
                                            <p>You have been invited to select your own meal for your next team event.</p>
                                            <span>{date ? (format(new Date(date), 'MMMM dd, EEEE') + ' at ' + format(new Date(date), 'h:mm aaa')) : 'loading...'}</span>
                                        </div>
                                        : auth
                                            ? <div className="modal__info--main">
                                                <h2>Sign Up</h2>
                                                <p>You have been invited to select your own meal for your next team event. Please create your account by filling in the below fields. You only have to do this once. Once your account is created you can sign in by using your mobile number only.</p>
                                                <span>{date ? (format(new Date(date), 'MMMM dd, EEEE') + ' at ' + format(new Date(date), 'h:mm aaa')) : 'loading...'}</span>
                                            </div>
                                            : <div className="modal__info--main">
                                                <h2>Sign in and join - {groupOrders?.name}</h2>
                                                <p>Enter in the mobile number that you used when creating your account below to sign in and join this group order.</p>
                                                <span>{date ? (format(new Date(date), 'MMMM dd, EEEE') + ' at ' + format(new Date(date), 'h:mm aaa')) : 'loading...'}</span>
                                            </div>
                                    }
                                    {textError || error ? <div className='form__error'>{textError || error}</div> : null}
                                    {choiceAuth
                                        ? <div className="person__status">
                                            <div className="elem__person">
                                                <div className="person__top">
                                                    <h2>
                                                        <span><img src="/img/personplus.svg" alt="personplus" /></span>
                                                        First time joining a <br /> group order?
                                                    </h2>
                                                </div>
                                                <div className="person__button create__account">
                                                    <button onClick={() => clickChoiceAuth('SIGNIN')} type="button">Create Account</button>
                                                </div>
                                            </div>
                                            <div className="elem__person long__person">
                                                <div className="person__top">
                                                    <h2>
                                                        <span><img src="/img/personlogined.svg" alt="personplus" /></span>
                                                        Have you already <br /> created an account?
                                                    </h2>
                                                </div>
                                                <div className="person__button login__account">
                                                    <button onClick={() => clickChoiceAuth('SIGNUP')} type="button">Log In</button>
                                                </div>
                                            </div>
                                        </div>
                                        : <div className="modal__form">
                                            <Form validationSchema={schema} defaultValues={{image_base64: null, user_id: user_id, id: groupOrderId}} action={FORM_MODAL_AUTH} name='modalAuth'>
                                                <ModalAuthWrap additional_delivery_options={groupOrders?.additional_delivery_options} auth={auth} setAuth={setAuth} user_id={user_id} groupOrderId={groupOrderId} />
                                            </Form>
                                        </div>
                                    }
                                    <div className="modal__footer">
                                        <p>Orders are processed during business hours Mon-Fri, 6AM-5PM (PST) and your
                                            order may be subject to restaurant and delivery capacity. We will only
                                            contact you should any issues arise with your order request. Eat Well!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ModalAuth