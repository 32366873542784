import {
    ADD_MODAL_PRODUCT,
    CLOSE_MODAL_AUTH,
    MODAL_TOGGLE_PRODUCT,
    MODAL_TOGGLE, CHANGE_MODAL_PRODUCT, MODAL_CHANGE_ORDER, NEW_USER_MODAL, MODAL_CLEAR_OLD_GO,
} from "./Modal.constant";
import {clearObject} from "../../hooks/clearObject";

const initialState = {}

const Modal = (state = initialState, action) => {
    switch (action.type) {
        case NEW_USER_MODAL:
            return {
                ...state,
                [action.payload]: {
                    isModalProduct: false,
                    isModalCloseSettingsDelivery: false,
                    isModalChangeOrder: false,
                    isModalOrderEndCompleted: false,
                    isModalOrderEndCancelled: false,
                    changeOrderInfo: null,
                    isModalAuth: true,
                    isModalEditProfile: false,
                    isModalReplaced: false,
                    isModalShareLink: false,
                    isModalConfirmation: false,
                    isModalOrderSuccess: false,
                    modalProduct: null,
                    modalProductFlag: 'ADD',
                    orderTeam: false,
                }
            };
        case CHANGE_MODAL_PRODUCT:
            let changeProduct = {...state[action.payload.user_id].modalProduct, ...action.payload.data}
            if (action.payload.data.menu_items_mapping && Object.values(action.payload.data.menu_items_mapping).length) {
                let total_price = 0
                state[action.payload.user_id].modalProduct.menu_items.forEach(item => {
                    Object.keys(action.payload.data.menu_items_mapping).forEach(key => {
                        if (+item.id === +key && +action.payload.data.menu_items_mapping[key].quantity){
                            total_price += item.price_cents * action.payload.data.menu_items_mapping[key].quantity;
                            changeProduct.menu_items_mapping[key].price = item.price_cents * action.payload.data.menu_items_mapping[key].quantity;
                        }
                    })
                })
                changeProduct.total_price_cents = total_price;
            } else {
                changeProduct.total_price_cents = state[action.payload.user_id].modalProduct.price_cents * action.payload.data.quantity;
            }
            return {
                ...state,
                [action.payload.user_id]: {
                    ...state[action.payload.user_id],
                    modalProduct: changeProduct
                }
            };
        case MODAL_CLEAR_OLD_GO:
            return {
                ...clearObject(state, action.payload)
            };
        case MODAL_CHANGE_ORDER:
            return {
                ...state,
                [action.payload.user_id]: {
                    ...state[action.payload.user_id],
                    [action.payload.slug]: !state[action.payload.user_id][action.payload.slug],
                    changeOrderInfo: action.payload.info
                }
            };
        case MODAL_TOGGLE:
            return {
                ...state,
                [action.payload.user_id]: {
                    [action.payload.data]: action.payload.boolean !== undefined ? action.payload.boolean : !state[action.payload.user_id][action.payload.data]
                }
            };
        case MODAL_TOGGLE_PRODUCT:
            let modalProduct = state[action.payload].isModalProduct === true ? null : state[action.payload].modalProduct
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    modalProduct: modalProduct,
                    isModalProduct: !state[action.payload].isModalProduct
                }
            };
        case CLOSE_MODAL_AUTH:
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    isModalAuth: false
                }
            };
        case ADD_MODAL_PRODUCT:
            let menu_items_mapping = {}
            action.payload.product.menu_items.forEach(item => (
                typeof(action.payload.product.menu_items_mapping[item.id]) === 'number' && (menu_items_mapping[item.id] = {
                    price: item.price_cents * action.payload.product.menu_items_mapping[item.id],
                    quantity: action.payload.product.menu_items_mapping[item.id]
                })
            ))
            return {
                ...state,
                [action.payload.user_id]: {
                    ...state[action.payload.user_id],
                    modalProductFlag: action.payload.flag,
                    orderTeam: action.payload.orderTeam,
                    modalProduct: {
                        ...action.payload.product,
                        menu_items_mapping: Object.keys(menu_items_mapping).length ? menu_items_mapping : action.payload.product.menu_items_mapping
                    },
                }
            };
        default:
            return state;
    }
}

export default Modal;