import React from 'react';
import './style.scss'
import {format} from "date-fns";
import {useDispatch} from "react-redux";
import {MODAL_TOGGLE} from "../../../store/Modal/Modal.constant";
import ImagePreview from "../../../modules/ui/ImagePreview";

const Invitation = ({user_id, groupOrders, settings, isJoin}) => {
    const dispatch = useDispatch();
    const presets = groupOrders?.preset;
    const shareLink = () => {
        dispatch({type: MODAL_TOGGLE, payload: {data: 'isModalShareLink', user_id: user_id}})
    }

    return (
        <div className="invitation__block">
            <div className="container">
                <div className="invitation__head" style={{background: `URL("${groupOrders?.preset?.banner_image_url}") center center / cover no-repeat`}}>
                    <div className="invitation__box">

                        <div className="welcome__invitation">
                            <div className="welcome__date">
                                <span>{presets?.name ? presets.name : 'loading...'}</span>
                            </div>
                            <p>{presets?.short_address ? presets.short_address : 'loading...'}</p>
                        </div>
                        <div className="invitation__desc">
                            <span>{groupOrders?.name}</span>
                            <p>{groupOrders?.delivery_date_time ? format(new Date(groupOrders.delivery_date_time * 1000), "EEEE, MMMM do, h:mm aaa") : 'loading...'}</p>
                        </div>
                    </div>
                    <a href={groupOrders?.preset?.shared_url ? groupOrders?.preset?.shared_url : '/'}>
                        <ImagePreview image={groupOrders?.preset?.chefs[0]?.profile_image_url} alt="invititation" />
                    </a>
                </div>
                <div className="orders__info">
                    <div className="orders__left">
                        <div className="elem__order">
                            <div className="order__image">
                                <img src="/img/floaticon1.svg" alt="floaticon" />
                            </div>
                            <div className="order__info">
                                <span>Budget Left:</span>
                                <p>{groupOrders?.per_person_budget ? groupOrders?.tax_info?.currency + (settings.budget / 100).toFixed(2) : 'loading...'}</p>
                            </div>
                        </div>
                        <div className="spacer" />
                        <div className="elem__order">
                            <div className="order__image">
                                <img src="/img/floaticon2.svg" alt="floaticon" />
                            </div>
                            <div className="order__info">
                                <span>Order must be submitted by:</span>
                                <p>
                                    {groupOrders?.order_deadline && format(groupOrders?.order_deadline * 1000, 'EEEE MMMM do') === format(new Date(), 'EEEE MMMM do') ? '(TODAY) ' : null}
                                    {groupOrders?.order_deadline ? format(new Date(groupOrders.order_deadline * 1000), 'EEEE, MMMM do, h:mm aaa') : 'loading...'}
                                </p>
                            </div>
                        </div>
                        <div className="spacer" />
                        <div className="elem__order">
                            <div className="order__image">
                                <img src="/img/floaticon3.svg" alt="floaticon" />
                            </div>
                            <div className="order__info">
                                <span>Group</span>
                                <p>{groupOrders ? `${groupOrders?.joined_member_count} out of ${groupOrders.head_count} joined` : 'loading...'}</p>
                            </div>
                        </div>
                    </div>
                    <div className="orders__right">
                        <button type='button' onClick={shareLink}>
                            <span><img src="/img/shareicon.svg" alt="shareicon" /></span> Share link
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Invitation